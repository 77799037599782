import React, {useState,useEffect,useContext} from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Wallet from '../assets/images/Wallet.svg';
import HistoryLogo from '../assets/images/History.svg';
import StarLogo from '../assets/images/star-svgrepo-com.svg';

import {useSearchParams,useNavigate} from "react-router-dom";
import {UserContext}  from './../App';
import i18next from 'i18next';

function Main() {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [backLoading, setBackLoading] = useState(false);
   const [searchParams] = useSearchParams();
   const token = searchParams.get('token');
   const lang = searchParams.get('lang','ru')

   useEffect(() => {
      i18next.changeLanguage(lang, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
         });
      console.log('lang',lang,i18next.languages)
   }, []);
   useEffect(() => {
      const requestOptions = {
         method: 'GET',
         headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
     };
      fetch('https://app.marketgame.space/api/profile/user-detail-web',requestOptions)
      .then((response) => response.json())
      .then(data => {
         setUser(data?.data);
      });

   }, []);
   
   function next() {
      if (cryptoW) navigate(`/crypto?token=${token}`);
      else navigate(`/fiat?token=${token}`);
   }
   
   
   function goHistory() {
      navigate(`/history?token=${token}`);
   }

   function toMarketPlace() {
      setBackLoading(true)

      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      // window.location.href  = `https://game.partia.money/edu/?token=${token}`;
      window.location.href = 'https://t.me/partiagame_bot?startapp'
   }

    return (
      <div className="App" style={{paddingTop:'40px'}}>
      <div className="chose__screen" >
         <div style={{display:'flex',alignItems:'center',justifyContent:'flex-end',marginBottom:25,}}>
         <div style={{justifyContent:'center',alignContent:'center',alignSelf:'center',display:'flex', alignItems:'center', border:'1px solid rgba(0,0,0,0.2)',borderRadius:15, padding:2, paddingLeft:6}}>
            <div style={{color:'rgba(0,0,0,0.5)',paddingRight:5, paddingTop:5}}>{user?.raiting}</div> 
               <img src={StarLogo} style={{width:17, }} className="App-logo" alt="logo" />
            </div>

            <div className="screen__tetle" style={{alignSelf: 'center',marginBottom:0, position: 'relative', margin: 'auto', left: '-{half width of left div}px'}} > {i18next.t('main.title')}</div>
            <button className="span-black" onClick={() => goHistory()} style={{marginRight:10}}>
               <img src={HistoryLogo} style={{width:20, }} className="App-logo" alt="logo" />
            </button>
            
         </div>

   
        
      <div 
        className={`screen__withdraw-profit ${!cryptoW ? "withdraw-profit_active" : ""}`}
        onClick={() => setCryptoW(false)}

        >
        <img src={Wallet} className="App-logo" alt="logo" />
         <p className="screen__withdraw-profit-name"> {i18next.t('main.fiat.title')} </p>
         <p className="screen__withdraw-profit-text"> {i18next.t('main.fiat.desc')}</p>
         <div className="arrow-right">
            <img src={Arrow} className="App-logo" alt="logo" />
         </div>
      </div>
      <div 
        className={`screen__withdraw-profit ${cryptoW ? "withdraw-profit_active" : ""}`}
        onClick={() => setCryptoW(true)}
        >
        <img src={creditCard} className="App-logo" alt="logo" />
         <p className="screen__withdraw-profit-name"> {i18next.t('main.crypto.title')}</p>
         <p className="screen__withdraw-profit-text"> {i18next.t('main.crypto.desc')}</p>
         <div className="arrow-right">
            <img src={Arrow} className="App-logo" alt="logo" />
         </div>
      </div>
      <button className="btn-gradient mt-2"   onClick={() => next()}>
         {i18next.t('main.next')}&#8195;
        <img src={longArrow} className="App-logo" alt="logo" />
      </button>

      <button className="btn-black mt-1" onClick={() => toMarketPlace()} >
         {
            backLoading===true ?  i18next.t('main.back.loading') : i18next.t('main.back.text')
         } 
      </button> 

      
   </div>
    </div>
    );
}

export default Main;
